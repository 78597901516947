
import { defineComponent, ref } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { useStore } from "vuex";
import * as Yup from "yup";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "password-reset",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const store = useStore();
    const router = useRouter();

    const submitButton = ref<HTMLButtonElement | null>(null);

    //Create form validation object
    const forgotPassword = Yup.object().shape({
      email: Yup.string()
        .email("Ingrese un correo electrónico válido")
        .required("El correo electrónico es obligatorio")
        .label("Email"),
    });

    //Form submit function
    const onSubmitForgotPassword = (values) => {
      // eslint-disable-next-line
      submitButton.value!.disabled = true;
      // Activate loading indicator
      submitButton.value?.setAttribute("data-kt-indicator", "on");

      // Send login request
      store
        .dispatch(Actions.FORGOT_PASSWORD, values)
        .then(() => {
          Swal.fire({
            title: "¡Correo enviado!",
            text: "Hemos enviado un correo con las instrucciones",
            icon: "success",
          });
          setTimeout(() => {
            router.push("/sign-in");
          }, 2000);
        })
        .catch(() => {
          console.log("jeje");
          // const [error] = Object.values(store.getters.getErrors);
          console.log(store.getters.getErrors);
          Swal.fire({
            title: "¡Ups!",
            html: store.getters.getErrors,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Intentar de nuevo",
            customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
            },
          });
        });

      submitButton.value?.removeAttribute("data-kt-indicator");
      // eslint-disable-next-line
      submitButton.value!.disabled = false;
    };

    return {
      onSubmitForgotPassword,
      forgotPassword,
      submitButton,
    };
  },
});
